var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export const mapTraderProfileFieldsToReq = (values) => {
    const { displayName, username, location, } = values;
    return {
        displayName,
        username,
        location: (location === null || location === void 0 ? void 0 : location.length) ? location : undefined,
        type: 'TRADER',
    };
};
export const getFileInputErrors = (translateFn) => ({
    INCORRECT_MIME_TYPE: translateFn('profile.edit_photo.file_upload.errors.incorrect_file_type', { ns: 'common' }),
    EXCEEDED_FILE_SIZE: translateFn('profile.edit_photo.file_upload.errors.big_file_size', { ns: 'common' }),
});
const AVATAR_SIZES = {
    avatar: {
        width: 128,
        height: 128,
    },
    thumbnail: {
        width: 58,
        height: 58,
    },
};
const getOriginalBinary = (original, fileName) => new Promise((resolve, reject) => {
    try {
        original.toBlob((blob) => {
            if (blob) {
                resolve(new File([blob], `${fileName}`));
            }
        });
    }
    catch (err) {
        reject(new Error(`${err}`));
    }
});
const resizeCanvas = (_a) => __awaiter(void 0, [_a], void 0, function* ({ originalCanvas, originalFileName = undefined, sizes, }) {
    // Get the resized canvas and the context
    const resizedCanvas = document.createElement('canvas');
    resizedCanvas.width = sizes.width;
    resizedCanvas.height = sizes.height;
    const resizedCanvasContext = resizedCanvas.getContext('2d');
    if (resizedCanvasContext) {
        // Draw the original image onto the resized canvas with new dimensions
        resizedCanvasContext
            .drawImage(originalCanvas, 0, 0, originalCanvas.width, originalCanvas.height, 0, 0, resizedCanvas.width, resizedCanvas.height);
        return new Promise((resolve) => {
            resizedCanvas.toBlob((blob) => {
                if (blob) {
                    resolve(new File([blob], `${originalFileName || ''}`));
                }
            });
        });
    }
    return '';
});
const getResizedImage = ({ avatar, imageSizes, imageFileName, }) => new Promise((resolve, reject) => {
    try {
        const result = resizeCanvas({
            originalCanvas: avatar,
            sizes: imageSizes,
            originalFileName: imageFileName,
        });
        resolve(result);
    }
    catch (err) {
        reject(new Error(`${err}`));
    }
});
const getAvatarForDisplay = (original, imageFileType) => new Promise((resolve, reject) => {
    try {
        const resizedCanvas = document.createElement('canvas');
        resizedCanvas.width = AVATAR_SIZES.avatar.width;
        resizedCanvas.height = AVATAR_SIZES.avatar.height;
        const resizedCanvasContext = resizedCanvas.getContext('2d');
        if (resizedCanvasContext) {
            resizedCanvasContext
                .drawImage(original, 0, 0, original.width, original.height, 0, 0, resizedCanvas.width, resizedCanvas.height);
            resolve(resizedCanvas.toDataURL(imageFileType));
        }
    }
    catch (err) {
        reject(new Error(`${err}`));
    }
});
export const getAvatarEncodedImages = ({ avatar, imageFileType, imageFileName, }) => Promise.all([
    getOriginalBinary(avatar, imageFileName),
    getResizedImage({ avatar, imageSizes: AVATAR_SIZES.avatar, imageFileName }),
    getResizedImage({ avatar, imageSizes: AVATAR_SIZES.thumbnail, imageFileName }),
    getAvatarForDisplay(avatar, imageFileType),
]).then((value) => ({ status: 'success', value }), (errors) => ({ status: 'error', errors }));
